<template>
    <div>
        <b-alert :variant="loading?'primary':'danger'" :show="!items">
            <div class="alert-body py-3 px-2">
                <span><strong v-if="loading">Veriler yükleniyor. <b-spinner label="Loading..." /></strong><strong v-else>Veri bulunamadı.</strong></span>
            </div>
        </b-alert>
        <b-card v-if="items">

            <div class="custom-search d-flex justify-content-between">
                <b-form-group>
                    <b-button variant="outline-primary" @click="showInsertForm=!showInsertForm">Ekle</b-button>
                </b-form-group>
            </div>

            <div v-if="showInsertForm" class="border-1 border-secondary p-2 mb-2 rounded-lg">
                <b-form @submit.prevent="insertNew">
                    <b-row>

                        <b-col sm="6" md="4" lg="6" xl="3" class="d-flex align-items-center">
                            <b-form-group class="w-100">
                                <b-form-input v-model="insertData.name" placeholder="Paket Adı" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4" lg="6" xl="3" class="d-flex align-items-center">
                            <b-form-group class="w-100">
                                <b-form-input v-model="insertData.description" placeholder="Açıklama" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="5" md="3" lg="5" xl="2" class="d-flex align-items-center">
                            <b-form-group class="w-100">
                                <b-form-input v-model="insertData.price" placeholder="Ücret" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="5" md="3" lg="5" xl="2" class="d-flex align-items-center">
                            <b-form-group class="w-100">
                                <b-form-input v-model="insertData.membershipDays" placeholder="Üyelik Süresi (Gün)" />
                            </b-form-group>
                        </b-col>

                        <b-col sm="2" class="d-flex align-items-center">
                            <b-form-group>
                                <b-button variant="outline-primary px-1" type="submit">
                                    <feather-icon icon="SaveIcon" />
                                </b-button>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </div>


            <!-- table -->
            <vue-good-table
                :columns="columns"
                :rows="items"
                :search-options="{enabled: false}"
                :pagination-options="{ enabled: false}"
                :sort-options="{enabled: false}">
                <template
                    slot="table-row"
                    slot-scope="props"
                >


                    <div v-if="props.column.field === 'name'">
                        <b-form-input class="tableInput" v-model="items[getItemIndex(props.row.id)].name" :readonly="$store.state.app.editableSlotId !== props.row.id" />
                    </div>
                    <div v-else-if="props.column.field === 'description'">
                        <b-form-input class="tableInput" v-model="items[getItemIndex(props.row.id)].description" :readonly="$store.state.app.editableSlotId !== props.row.id" />
                    </div>
                    <div v-else-if="props.column.field === 'price'">
                        <b-form-input class="tableInput" v-model="items[getItemIndex(props.row.id)].price" :readonly="$store.state.app.editableSlotId !== props.row.id" />
                    </div>
                    <div v-else-if="props.column.field === 'membershipDays'">
                        <b-form-input class="tableInput" v-model="items[getItemIndex(props.row.id)].membershipDays" :readonly="$store.state.app.editableSlotId !== props.row.id" />
                    </div>


                    <div v-else-if="props.column.field === 'action'">
                        <b-button-group>
                            <b-button variant="outline-primary" @click="updateItem(props.row.id)" v-if="$store.state.app.editableSlotId === props.row.id">
                                <feather-icon icon="SaveIcon" />
                            </b-button>
                            <b-button variant="outline-primary" @click="setEditable(props.row.id)">
                                <feather-icon :icon="$store.state.app.editableSlotId !== props.row.id ? 'EditIcon':'RefreshCcwIcon'" />
                            </b-button>
                            <b-button variant="outline-primary" @click="deleteItem(props.row.id)">
                                <feather-icon icon="TrashIcon" />
                            </b-button>
                        </b-button-group>
                    </div>

                    <!-- Column: Common -->
                    <div v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>
            </vue-good-table>
        </b-card>
    </div>
</template>

<script>
import {
    BAlert,
    BSpinner,
    BCard,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BButtonGroup,
    BButton,
    BForm,
    BRow,
    BCol


} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'
import formData from 'form-data'
import vSelect from "vue-select";

export default {
    name: 'packages',
    components: {
        BAlert,
        BSpinner,
        BCard,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        VueGoodTable,
        BImg,
        BButtonGroup,
        BButton,
        BForm,
        BRow,
        BCol,
        vSelect

    },
    data(){
        return {
            loading: true,
            items: false,
            preChange : {},
            showInsertForm : false,
            insertData: {
                name: null,
                description: null,
                price: 0,
                membershipDays: 0
            },
            types: [],
            categoryList:[],
            pageLength: 20,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'id',
                    sortable: true,
                    type:'number',
                    width: '80px',
                    align: 'center',
                },
                {
                    label: 'Paket Adı',
                    field: 'name'
                },
                {
                    label: 'Açıklama',
                    field: 'description'
                },
                {
                    label: 'Fiyat',
                    field: 'price'
                },
                {
                    label: 'Üyelik Süresi (Gün)',
                    field: 'membershipDays'
                },
                {
                    label: 'İşlemler',
                    field: 'action',
                    sortable: false,
                    width: '160px',
                    align: 'center'
                },
            ]
        }
    },
    created() {
        let t = this


        t.$http.get('/admin/membership-packages/get/'+this.$route.params.type)
            .then(res => {
                if(res.data.status){
                    t.items = res.data.data
                }else if(res.data.message){
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Hata`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    })
                }
            }).catch(error=>{
            console.log(error)
        }).then(()=>{
            t.loading = false
        })

        store.commit('app/SET_EDITABLE', 0)
    },
    methods: {
        setEditable(itemId){
            let fIndex = this.getItemIndex(itemId)
            let t = this
            if(store.state.app.editableSlotId === 0){
                this.preChange = structuredClone(this.items[fIndex])
                store.commit('app/SET_EDITABLE', itemId)
            }else if(store.state.app.editableSlotId === itemId){
                this.$swal({
                    title: 'Emin misiniz?',
                    text: "Yaptığınız değişiklikler kaybolacaktır.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'İptal',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true
                }).then(result => {
                    if(result.isConfirmed){
                        this.resetItems(fIndex)
                        store.commit('app/SET_EDITABLE', 0)
                    }
                })
            }else{
                this.$swal({
                    title: 'Emin misiniz?',
                    text: "Mevcut düzenlemeyi tamamlamadan devam ederseniz, yaptığınız değişiklikler kaybolacaktır.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'İptal',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true
                }).then(result => {
                    if(result.isConfirmed){
                        this.resetItems(t.getItemIndex(store.state.app.editableSlotId))
                        t.preChange = structuredClone(t.items[fIndex])
                        store.commit('app/SET_EDITABLE', itemId)
                    }
                })
            }
        },
        updateItem(id){

            let t = this
            let data = new formData()
            let fIndex = this.getItemIndex(id)
            let item = this.items[fIndex]
            data.append('name', item.name)
            data.append('description', item.description)
            data.append('price', item.price)
            data.append('membershipDays', item.membershipDays)

            store.commit('app/SET_LOADING', true)
            t.$http.post(
                'admin/membership-packages/edit/'+id,
                data,
                {onUploadProgress: progress=>console.log(progress)}
            ).then((result)=>{
                if(result.data.status){
                    if(result.data.message){
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Başarılı!`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: result.data.message,
                            },
                        })
                    }
                }else{
                    t.$swal({
                        title: 'Hata!',
                        text: result.data.message?result.data.message:'İçerik Güncellenemedi. Lütfen daha sonra tekrar deneyin.',
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    })
                    t.resetItems(fIndex)
                }
            }).catch((error)=>{
                console.log(error)

                t.$swal({
                    title: 'Hata!',
                    text: 'İçerik güncellenemedi. Lütfen daha sonra tekrar deneyin.',
                    icon: 'error',
                    confirmButtonText: 'Tamam'
                })
                t.resetItems(fIndex)
            }).then((e)=>{
                if(item.fileToUpload){
                    delete item.fileToUpload
                }
                store.commit('app/SET_EDITABLE', 0)
                store.commit('app/SET_LOADING', false)
            })

        },
        deleteItem(itemId){
            let t = this

            t.$swal({
                title: 'Emin misiniz?',
                text: 'İçeriği sildiğinizde bağlantılı tüm verilerle beraber tamamen silinecektir. Bu içeriği silmek istediğinize emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Sil!',
                cancelButtonText: 'İptal',
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-outline-info ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {

                    store.commit('app/SET_LOADING', true)
                    t.$http.delete('admin/membership-packages/delete/'+itemId).then(function (e){
                        if(e.data.status){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Başarılı',
                                    text: e.data.message?e.data.message:'İçerik başarıyla silindi.',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                            let itemIndex = t.items.findIndex(e=>{return e.id === itemId})
                            t.items.splice(itemIndex, 1)
                        }
                    }).catch(e=>{
                        console.log(e)
                    }).then(f=>{
                        store.commit('app/SET_LOADING', false)
                    });

                } else if (result.dismiss === 'cancel') {
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `İptal Edildi!`,
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: 'İçerik silme işlemi iptal edildi.',
                        },
                    })
                }
            })
        },
        getItemIndex(id){
            return this.items.findIndex(e=>{return e.id===id})
        },
        resetItems(curr){
            let t = this;
            let keys = Object.keys(t.preChange)
            for (let i = 0; i<keys.length; i++){
                t.items[curr][keys[i]] = t.preChange[keys[i]]
            }
            this.$refs['fileInput'+t.items[curr].id].value = null
            this.$refs['img'+t.items[curr].id].src = this.$basePath+t.items[curr].fileName
            delete this.items[curr].fileToUpload
        },
        insertNew(){
            let t = this
            let data = new formData()


            data.append('name', t.insertData.name)
            data.append('description', t.insertData.description)
            data.append('price', t.insertData.price)
            data.append('membershipDays', t.insertData.membershipDays)

            store.commit('app/SET_LOADING', true)
            t.$http.post(
                'admin/membership-packages/add/'+this.$route.params.type,
                data,
                {onUploadProgress: progress=>console.log(progress)}
            ).then((result)=>{
                if(result.data.status){

                    t.insertData.name=null
                    t.insertData.description=null
                    t.insertData.price=0
                    t.insertData.membershipDays=null

                    t.items.push(result.data.data)

                    if(result.data.message){
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Başarılı!`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: result.data.message,
                            },
                        })
                    }
                }else{
                    t.$swal({
                        title: 'Hata!',
                        text: result.data.message?result.data.message:'İçerik eklenemedi. Lütfen daha sonra tekrar deneyin.',
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    })
                }
            }).catch((error)=>{
                console.log(error)
                t.$swal({
                    title: 'Hata!',
                    text: 'İçerik eklenemedi. Lütfen daha sonra tekrar deneyin.',
                    icon: 'error',
                    confirmButtonText: 'Tamam'
                })
            }).then((e)=>{
                store.commit('app/SET_LOADING', false)
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.vs__search{
    border: none !important;
}
.tableInput[readonly]{
    border: none !important;
}
.hiddenFile{
    position: fixed;
    top: -9999px;
    left: -9999px;
}
</style>
